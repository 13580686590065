import React, { useEffect, useState } from 'react';
import { FiSearch } from "react-icons/fi";
import './styles.css';
import api from './services/api';
import { initializeApp } from "firebase/app";
import { updateDoc, collection, getDocs, getFirestore, doc } from "firebase/firestore";

function App() {
  const [paymentList, setPaymentList] = useState([])

  const [orderInput, setOrderInput] = useState("")
  const [order, setOrder] = useState({})
  // const [order, setOrder] = useState({tx_id: "", value: ""})

  const [adaPrice, setAdaPrice] = useState("")
  const [paymentPrice, setPaymentPrice] = useState("")

  const [isManual, setIsManual] = useState(false)
  const [isPaymentCompleted, setPaymentCompleted] = useState(false)

  const [txId, setTxId] = useState("")

  const firebaseApp = initializeApp({
    apiKey: "AIzaSyDgD8CrHVQAyRqEQT-bTReu7r_50Awo6wg",
    authDomain: "apoiadores-cardanistas.firebaseapp.com",
    projectId: "apoiadores-cardanistas",
  });

  const db = getFirestore(firebaseApp);
  const paymentCollectionRef = collection(db, "payment");

  useEffect(() => {
    const getPaymentList = async () => {
      const data = await getDocs(paymentCollectionRef);
      console.log(data); //TODO: remove
      console.log(data.docs.map( (doc) => ({ ...doc.data(), id: doc.id}) )); //TODO: remove
      setPaymentList(data.docs.map( (doc) => ({ ...doc.data(), id: doc.id}) ))
    };
    getPaymentList();
  }, []);

  async function getAdaPrice(paymentPriceBRL) {
    try{
      const response = await api.get(``)
      let price = response.data["price"]
      setAdaPrice(price);
      setPaymentPrice(Math.round((paymentPriceBRL/price) * 100) / 100);
    }catch{
      alert("Ops erro ao buscar o preço ADA/BRL");
    }
  };

  async function handleSearch() {
    if (orderInput === '') {
      alert("Informe a ordem de pagamento recebida por email!");
      return;
    } else {
      const orderSearchResult = await paymentList.filter(function(x) {
        if (x.order === orderInput) return x;
        return undefined;
      });
      if (typeof orderSearchResult !== 'undefined' && orderSearchResult.length > 0) {
        setOrder(orderSearchResult[0]);
        // setOrder( order => ({
        //   ...orderSearchResult[0]
        // }));
        getAdaPrice(orderSearchResult[0].price_brl);
      } else {
        setOrderInput("");
        alert("Informe a ordem de pagamento recebida por email!");
        return;
      }
    }
  };

  async function savePayment() {
    if (txId === '') {
      alert("Informe o ID da transação!");
      return;
    } else {
      try {
        const paymentRef = doc(db, "payment", order.id);
        await updateDoc(paymentRef, {
          "tx_id": txId,
          "value": paymentPrice
        });
        setPaymentCompleted(!isPaymentCompleted);
        alert("Pagamento registrado!\n\nAguardaremos as confirmações da rede, e entraremos em contato se necessário.\n\nAgradecemos a você por ser um(a) apoiador(a) da Cardanistas!")
      } catch (error) {
        alert("Ops erro registrar o pagamento! Tente novamente!");
        console.log(error);
      }
    }
  }

  return (
    <div className="container">
      <h1 className="title">
        Apoiadores Cardanistas
      </h1>
      <div className="containerInput">
        <input
          type="text"
          placeholder="Informe ordem de pagamento..."
          value={orderInput}
          onChange={ (e) => setOrderInput(e.target.value) }
        />
        <button className="buttonSearch" onClick={handleSearch}>
          <FiSearch size={25} color="#FFF"/>
        </button>
      </div>

      {Object.keys(order).length > 0 && (
        <main className='main'>
          <h2>Pagamento de Assinatura</h2>
          <h3>Período: {new Date(order.period_start.seconds * 1000).toLocaleDateString("pt-BR")} - {new Date(order.period_end.seconds * 1000).toLocaleDateString("pt-BR")}</h3>
          <h3>Preço: R$ {order.price_brl.toFixed(2)}</h3>
          <h3>{paymentPrice} ADA</h3>
          <h3>(Cotação ADA/BRL: {adaPrice})</h3>

          <div>
            <h3 className='paymentOptionsHeader'>Forma de Pagamento:</h3>
            <span className="paymentOptions">
              <button className="buttonDapp" onClick={() => {alert("Funcionalidade não implementada, utilize o envio manual!")}}>dApp Connector</button>
              <button className="buttonManual" onClick={() => setIsManual(!isManual)}>Envio Manual</button>
            </span>
            <span>PS: Se precisar de um P2P para comprar ADA, chama no <a href='https://api.whatsapp.com/send?phone=5571996258096' target="_blank" rel="noopener noreferrer">Zap.</a></span>
          </div>
      </main>
      )};

      {(isManual) && (
        <div>
          <main className='main'>
            <h2>Dados para envio manual:</h2>

            <span>Quantidade: {paymentPrice} ADA</span>
            <span>Endereço:</span>
            <textarea disabled={true} rows="3" cols="40" maxLength="500" defaultValue="addr1q8yxkrg0plq227q2878jsdy8k2pgxpqe7dxstqgvt407ggpzv3q98krwamtx85a6sr228vxa279atdn8dg7m58snj9ps94nq26"></textarea>
            <span>ou</span>
            <span>ADA Handle 1: $cards</span>
            <span>ou</span>
            <span>ADA Handle 2: $cardanistas</span>
          </main>

        <div className="container2">
          <div className="txIdInput">
            <input
              type="text"
              placeholder="Informe o ID da transação..."
              value={txId}
              onChange={ (e) => setTxId(e.target.value) }
              disabled={isPaymentCompleted}
            />
            <button className="buttonSend" disabled={isPaymentCompleted} onClick={savePayment}>Enviar</button>
          </div>
        </div>
      </div>
      )};
    </div>
  );
}

export default App;
